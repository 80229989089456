/**
 * @prerequisiteTask - Move all this to server side
 */
const placementIds = {
  advertenties_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      criteo: {
        '728x90': 1395087,
      },
    },
    mobile: {
      improvedigital: 13304385,
    },
  },
  l1_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': 1395107,
        '728x90': 1395087,
      },
      tmp: 21471518,
    },
    mobile: {
      improvedigital: 13304385,
      tmp: 21471518,
    },
  },
  l1_left: {
    default: {
      improvedigital: 13304379,
      rubicon: 1203140,
      appnexus: 15013993,
      ix: 216236,
      criteo: {
        '300x250': 1394963,
      },
      tmp: 22708481,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708481,
    },
  },
  l1_right: {
    default: {
      improvedigital: 13304381,
      rubicon: 1203144,
      appnexus: 15013995,
      ix: 216238,
      criteo: {
        '300x250': 1394964,
      },
      tmp: 22708521,
    },
    mobile: {
      improvedigital: 13304386,
      tmp: 22708521,
    },
  },
  lrp_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': '1395107',
        '728x90': '1395087',
        '300x250': '1394960',
        '320x50': '1394979',
        '320x100': '1394972',
        '320x240': '1394975',
      },
      tmp: 22708523,
    },
    mobile: {
      improvedigital: 13304385,
      tmp: 22708523,
    },
  },
  lrp_bot: {
    default: {
      improvedigital: 13304382,
      rubicon: 1203146,
      appnexus: 15013996,
      ix: 216239,
      criteo: {
        '970x250': 1395108,
        '728x90': 1395088,
      },
      tmp: 22709171,
    },
    mobile: {
      improvedigital: 13304387,
      tmp: 22709171,
    },
  },
  lrp_gv_mid: {
    default: {
      improvedigital: 13304383,
      rubicon: 1203148,
      appnexus: 15013999,
      ix: 216240,
      criteo: {
        '336x280': 1394984,
        '300x250': 1394960,
      },
      tmp: 22709010,
    },
    mobile: {
      improvedigital: 13304388,
      tmp: 22709010,
    },
  },
  lrp_left: {
    default: {
      improvedigital: 13304379,
      rubicon: 1203140,
      appnexus: 15013993,
      ix: 216236,
      criteo: {
        '120x600': 1394947,
        '160x600': 1394959,
        '300x250': 1394963,
        '300x600': 1394968,
      },
      tmp: 22708524,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708524,
    },
  },
  lrp_left1: {
    default: {
      improvedigital: 13304380,
      rubicon: 1203142,
      appnexus: 15013994,
      ix: 216237,
      criteo: {
        '120x600': 1394948,
        '160x600': 1394958,
        '300x250': 1394964,
        '300x600': 1394967,
      },
      tmp: 22708525,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708525,
    },
  },
  lrp_mid: {
    default: {
      improvedigital: 13304383,
      rubicon: 1203148,
      appnexus: 15013999,
      ix: 216240,
      criteo: {
        '728x90': 1395088,
        '300x250': 1394961,
      },
      tmp: 22709011,
    },
    mobile: {
      improvedigital: 13304388,
      tmp: 22709011,
    },
  },
  home_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      gumgum: 1, // this is to enable gumgum config for this position
      criteo: {
        '970x250': 1395107,
        '728x90': 1395087,
        '300x250': 1394960,
        '320x50': 1394979,
        '320x100': 1394972,
        '320x240': 1394975,
      },
      tmp: 22708565,
    },
    mobile: {
      improvedigital: 13304385,
      tmp: 22708565,
    },
  },
  vip_right: {
    default: {
      improvedigital: 13304381,
      rubicon: 1203144,
      appnexus: 15013995,
      ix: 216238,
      criteo: {
        '300x600': 1394968,
        '300x250': 1394960,
        '320x50': 1394979,
        '320x100': 1394972,
        '320x240': 1394975,
      },
      tmp: 22708566,
    },
    mobile: {
      improvedigital: 13304386,
      tmp: 22708566,
    },
  },
  vip_right1: {
    default: {
      improvedigital: 22182062,
      rubicon: 1508140,
      appnexus: 17957632,
      criteo: {
        '160x600': 1394959,
        '300x250': 1394963,
        '120x600': 1491292,
        '300x600': 1491293,
      },
      ix: 436346,
      tmp: 22708567,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708567,
    },
  },
  vip_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      criteo: {
        '970x250': 1395107,
        '728x90': 1395087,
      },
      tmp: 22708568,
    },
    mobile: {
      improvedigital: 13304385,
      tmp: 22708568,
    },
  },
  vip_mid: {
    default: {
      improvedigital: 22078739,
      rubicon: 1384510,
      appnexus: 16717053,
      criteo: {
        '320x50': 1394980,
        '468x60': 1429554,
      },
      ix: 397156,
      tmp: 22709175,
    },
    mobile: {
      improvedigital: 22078739,
      tmp: 22709175,
    },
  },
  vip_left: {
    default: {
      improvedigital: 13304379,
      rubicon: 1203140,
      appnexus: 15013993,
      ix: 216236,
      criteo: {
        '120x600': 1394948,
        '160x600': 1394958,
        '300x250': 1394964,
        '300x600': 1394967,
      },
      tmp: 22708569,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708569,
    },
  },
  vip_bot: {
    default: {
      improvedigital: 13304382,
      rubicon: 1203146,
      appnexus: 15013996,
      ix: 216239,
      criteo: {
        '728x90': 1395091,
      },
      tmp: 21471513,
    },
    mobile: {
      improvedigital: 13304387,
      tmp: 21471513,
    },
  },
  vip_map_top: {
    default: {
      improvedigital: 21997267,
      rubicon: 1250046,
      appnexus: 15583127,
      ix: 357964,
      criteo: {
        '728x90': 1395089,
      },
      tmp: 22708570,
    },
    mobile: {
      improvedigital: [],
      tmp: 22708570,
    },
  },
  vip_img_bot: {
    default: {
      improvedigital: 21997266,
      rubicon: 1250040,
      appnexus: 15583126,
      ix: 357963,
      criteo: {
        '728x90': 1395088,
      },
      tmp: 22709177,
    },
    mobile: {
      improvedigital: [],
      tmp: 22709177,
    },
  },
  soi_top: {
    default: {
      improvedigital: 13304391,
      rubicon: 1203138,
      appnexus: 15013990,
      ix: 216235,
      criteo: {
        '728x90': 1395087,
        '300x250': 1394960,
        '320x50': 1394979,
        '320x100': 1394972,
        '320x240': 1394975,
      },
      tmp: 22708572,
    },
    mobile: {
      improvedigital: 13304385,
      tmp: 22708572,
    },
  },
};

export default placementIds;
