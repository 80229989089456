import SSP_PROVIDERS from '../v6/constants/SSP_PROVIDERS';
import getConfig from '../requestAds/getConfig';

const { SSP_IMPROVE, SSP_RUBICON, SSP_APPNEXUS, SSP_INDEXEXCHANGE, SSP_CRITEO, SSP_GUMGUM, SSP_TMP } = SSP_PROVIDERS;

const sspProvidersTWHFR = [SSP_RUBICON, SSP_APPNEXUS, SSP_INDEXEXCHANGE, SSP_CRITEO, SSP_TMP];

const { isConsentPresent, enableNonCompliantSsps } = getConfig();

if (isConsentPresent || enableNonCompliantSsps) {
  sspProvidersTWHFR.push(SSP_IMPROVE, SSP_GUMGUM);
}

export default sspProvidersTWHFR;
